<template>
  <div>
    <el-form v-if="flowStateCode === 'Supplement_tenant' && bankKey === 0 && !viewDetail()" ref="appForm" :model="appForm" :rules="rules" label-width="180px" :label-suffix="constants.labelSuffix">
      <ics-page-inner title="收款信息">
        <el-col :span="12">
          <el-form-item prop="firmName" label="收款人" :rules="{ required: true, message: '收款人', trigger: 'blur' }">
            <el-input v-model="appForm.firmName" placeholder="请输入收款人" />
          </el-form-item>
        </el-col>
        <el-col v-if="method !== 0" />
        <el-col v-if="method !== 0" :span="12">
          <el-form-item prop="bankName" label="银行名称" :rules="{ required: true, message: '银行名称', trigger: 'blur' }">
            <el-input v-model="appForm.bankName" placeholder="请输入银行名称" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="bankAccount" label="收款人账户" :rules="{ required: true, message: '收款人账户', trigger: 'blur' }">
            <el-input v-model="appForm.bankAccount" placeholder="请输入收款人账户" @input="(v) => (appForm.bankAccount = v.replace(/\s/g, ''))" />
          </el-form-item>
        </el-col>
      </ics-page-inner>
      <ics-page-inner title="附件">
        <el-col :span="12">
          <el-form-item label="文件" prop="files">
            <p>
              <debounce-button type="primary" @click="clickDialog">
                点击上传
              </debounce-button>
            </p>
            <p v-for="(item, index) in filesList" :key="index">
              <a href="javascript:" class="text-btn" @click="utils.downloadP('credit', item.url)">{{ item.name }}</a>
              <a href="javascript:" class="text-btn danger" @click="deleteFile(index)"><i class="el-icon-delete" /></a>
            </p>
          </el-form-item>
        </el-col>
        <ics-button-inner :loading="loadingSubmit.submit" submit-title="提交" cancel-title="返回" @submit="submitForms" />
      </ics-page-inner>
    </el-form>
    <el-form v-if="bankKey === 1" label-width="180px" :label-suffix="constants.labelSuffix">
      <ics-page-inner title="收款信息">
        <el-col :span="12">
          <el-form-item label="收款人">
            <p>{{ utils.isEffectiveCommon(appForm.firmName) }}</p>
          </el-form-item>
        </el-col>
        <el-col v-if="method !== 0" />
        <el-col v-if="method !== 0" :span="12">
          <el-form-item label="银行名称">
            <p>{{ utils.isEffectiveCommon(appForm.bankName) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收款账号">
            <p>{{ utils.isEffectiveCommon(appForm.bankAccount) }}</p>
          </el-form-item>
        </el-col>
      </ics-page-inner>
      <ics-page-inner v-if="!_.isEmpty(filesList)" title="附件">
        <el-col :span="12">
          <el-form-item label="文件">
            <p v-for="(item, index) in filesList" :key="index">
              <a href="javascript:" class="text-btn" @click="utils.downloadP('credit', item.url)">{{ item.name }}</a>
            </p>
          </el-form-item>
        </el-col>
      </ics-page-inner>
    </el-form>
    <ics-common-upload-inner :upload-dialog="dialog.upload" bucket="credit" @onSuccessFile="onSuccessFile" />
  </div>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsCommonUploadInner from '@/components/common-upload-inner'
export default {
  name: 'IcsBankFilesInner',
  components: { IcsCommonUploadInner },
  mixins: [routeEnterMixin],
  props: {
    loadingSubmit: {
      type: Object,
      default() {
        return {}
      }
    },
    bankKey: {
      type: Number,
      default: 0
    },
    method: {
      type: [Number, String],
      default: ''
    },
    flowStateCode: {
      type: [Number, String],
      default: ''
    },
    bankFilesInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    viewEditInfo: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      appForm: {
        firmName: '',
        bankName: '',
        bankAccount: ''
      },
      dialog: {
        upload: {
          visible: false,
          title: '上传附件'
        }
      },
      filesList: [],
      rules: {}
    }
  },
  watch: {
    bankFilesInfo: {
      handler (val) {
        if (val) {
          this.getDataProcessing(val)
        }
      },
      immediate: true
    }
  },
  methods: {
    viewDetail () {
      if (['detail', 'audit'].includes(this.$route.params.editMode)) {
        return true
      } else {
        return false
      }
    },
    getDataProcessing (info) {
      const data = info || {}
      const {firmName, bankName, bankAccount} = data
      this.filesList = data.files
      this.appForm = this._.assign(this.appForm, {firmName, bankName, bankAccount})
    },
    clickDialog () {
      this.dialog.upload.visible = true
    },
    onSuccessFile (response) {
      response.data.url = response.data.path
      const data = []
      data.push(response.data)
      this.filesList = data
    },
    deleteFile (index) {
      this.filesList.splice(index, 1)
    },
    submitForms () {
      this.$refs.appForm.validate().then(() => {
        this.appForm.files = this.filesList
        this.$emit('submit', this.appForm)
      })
    }
  }
}
</script>

<style scoped>

</style>
