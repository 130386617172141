var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.stage === 0 && !_vm.isSupplement
        ? _c(
            "ics-page-inner",
            { attrs: { title: "产品信息", "show-header": _vm.showHeader } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "金融产品编码" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(
                            _vm.productDetail.productCode
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "金融产品名称" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(
                            _vm.productDetail.productName
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "隶属资方" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(_vm.productDetail.capName)
                        )
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "产品类型" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.statusFormat(
                            Number(_vm.productDetail.productFinancing),
                            "productType"
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "产品模型" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(
                            _vm.productDetail.productTypeName
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否支持线上放/还款" } },
                    [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.utils.statusFormat(
                              Number(_vm.productDetail.repaymentMethod),
                              "isOnLine"
                            )
                          )
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm.productDetail.repayment
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "repayment", label: "还款方式" } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.statusFormat(
                                  _vm.productDetail.repayment,
                                  "repaymentType"
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "服务费收取方式" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.statusFormat(
                            Number(_vm.productDetail.serviceChargeMethod),
                            "ownFunds"
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              ),
              _vm.productDetail.maxAmount
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "借贷金额上限(元)" } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.moneyFormat(
                                  _vm.productDetail.maxAmount,
                                  2
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productDetail.minCycleMin && _vm.productDetail.minCycleMax
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "免收费期限(天)" } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.isEffectiveCommon(
                                  _vm.productDetail.minCycleMin
                                ) +
                                  " ~ " +
                                  _vm.utils.isEffectiveCommon(
                                    _vm.productDetail.minCycleMax
                                  )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productDetail.maxPeriodMin &&
              _vm.productDetail.maxPeriodMax &&
              _vm.productDetail.repayment !== "regular"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "额度期限(天)" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.productDetail.maxPeriodMin
                              ) +
                                " ~ " +
                                _vm.utils.isEffectiveCommon(
                                  _vm.productDetail.maxPeriodMax
                                )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productDetail.interestRateMin &&
              _vm.productDetail.interestRateMax
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "日利率(%)" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.productDetail.interestRateMin
                              ) +
                                " ~ " +
                                _vm.utils.isEffectiveCommon(
                                  _vm.productDetail.interestRateMax
                                )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productDetail.penalTYtRateMin &&
              _vm.productDetail.penalTYtRateMax
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "罚息(%)" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.productDetail.penalTYtRateMin
                              ) +
                                " ~ " +
                                _vm.utils.isEffectiveCommon(
                                  _vm.productDetail.penalTYtRateMax
                                )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productDetail.overdueMin && _vm.productDetail.overdueMax
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "逾期期限" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.productDetail.overdueMin
                              ) +
                                " ~ " +
                                _vm.utils.isEffectiveCommon(
                                  _vm.productDetail.overdueMax
                                )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productDetail.roll
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否支持宽限期" } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.statusFormat(
                                  _vm.productDetail.roll,
                                  "rollType"
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productDetail.maxExtensionMin &&
              _vm.productDetail.maxExtensionMax &&
              _vm.productDetail.roll === "yes"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "宽限期最大时长" } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.isEffectiveCommon(
                                  _vm.productDetail.maxExtensionMin
                                ) +
                                  " ~ " +
                                  _vm.utils.isEffectiveCommon(
                                    _vm.productDetail.maxExtensionMax
                                  )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productDetail.repayment === "regular"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "期数" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.productDetail.periods
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productDetail.extensionRateMin &&
              _vm.productDetail.extensionRateMax &&
              _vm.productDetail.roll === "yes"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "宽限期利率(%)" } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.isEffectiveCommon(
                                  _vm.productDetail.extensionRateMin
                                ) +
                                  " ~ " +
                                  _vm.utils.isEffectiveCommon(
                                    _vm.productDetail.extensionRateMax
                                  )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productDetail.financingMin && _vm.productDetail.financingMax
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "融资比例(%)" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.productDetail.financingMin
                              ) +
                                " ~ " +
                                _vm.utils.isEffectiveCommon(
                                  _vm.productDetail.financingMax
                                )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productDetail.serviceChargeMin &&
              _vm.productDetail.serviceChargeMax
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "服务费比例(%)" } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.isEffectiveCommon(
                                  _vm.productDetail.serviceChargeMin
                                ) +
                                  " ~ " +
                                  _vm.utils.isEffectiveCommon(
                                    _vm.productDetail.serviceChargeMax
                                  )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productDetail.repayment === "regular"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "还款日：" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.productDetail.repaymentDay
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.stage === 1 && !_vm.isSupplement
        ? _c(
            "ics-page-inner",
            { attrs: { title: "产品信息", "show-header": _vm.showHeader } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "金融产品编码" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(
                            _vm.productDetail.productCode
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "金融产品名称" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(
                            _vm.productDetail.productName
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "隶属资方" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(_vm.productDetail.capName)
                        )
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "产品类型" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.statusFormat(
                            Number(_vm.productDetail.productFinancing),
                            "productType"
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "产品模型" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(
                            _vm.productDetail.productTypeName
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否支持线上放/还款" } },
                    [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.utils.statusFormat(
                              Number(_vm.productDetail.repaymentMethod),
                              "isOnLine"
                            )
                          )
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm.productDetail.repayment
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "repayment", label: "还款方式" } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.statusFormat(
                                  _vm.productDetail.repayment,
                                  "repaymentType"
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "服务费收取方式" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.statusFormat(
                            Number(_vm.productDetail.serviceChargeMethod),
                            "ownFunds"
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              ),
              _vm.productDetail.maxAmount
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "借贷金额上限(元)" } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.moneyFormat(
                                  _vm.productDetail.maxAmount,
                                  2
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productDetail.minCycle
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "免收费期限(天)" } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.isEffectiveCommon(
                                  _vm.productDetail.minCycle
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productDetail.maxPeriod &&
              _vm.productDetail.repayment !== "regular"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "额度期限(天)" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.productDetail.maxPeriod
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productDetail.interestRate
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "日利率(%)" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.productDetail.interestRate
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productDetail.penalTYtRate
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "罚息(%)" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.productDetail.penalTYtRate
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productDetail.roll
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否支持宽限期" } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.statusFormat(
                                  _vm.productDetail.roll,
                                  "rollType"
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productDetail.maxExtension && _vm.productDetail.roll === "yes"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "宽限期最大时长" } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.isEffectiveCommon(
                                  _vm.productDetail.maxExtension
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productDetail.overdue
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "逾期期限" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.productDetail.overdue
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productDetail.extensionRate &&
              _vm.productDetail.roll === "yes"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "宽限期利率(%)" } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.isEffectiveCommon(
                                  _vm.productDetail.extensionRate
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productDetail.financing
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "融资比例(%)" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.productDetail.financing
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productDetail.serviceCharge
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "服务费比例(%)" } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.isEffectiveCommon(
                                  _vm.productDetail.serviceCharge
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productDetail.repayment === "regular"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "期数" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.productDetail.periods
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.productDetail.repayment === "regular"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "还款日：" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.productDetail.repaymentDay
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.stage === 2 && !_vm.isSupplement && !_vm.viewDetail()
        ? _c(
            "ics-page-inner",
            { attrs: { title: "产品信息", "show-header": _vm.showHeader } },
            [
              _c(
                "el-form",
                {
                  ref: "appForm",
                  attrs: {
                    model: _vm.appForm,
                    rules: _vm.rules,
                    "label-width": "180px",
                    "label-suffix": _vm.constants.labelSuffix
                  }
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "金融产品编码" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.productDetail.productCode
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "金融产品名称" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.productDetail.productName
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "隶属资方" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.productDetail.capName
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "产品类型" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.statusFormat(
                                Number(_vm.productDetail.productFinancing),
                                "productType"
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "产品模型" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.productDetail.productTypeName
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否支持线上放/还款" } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.statusFormat(
                                  Number(_vm.productDetail.repaymentMethod),
                                  "isOnLine"
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.productDetail.repayment
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "repayment", label: "还款方式" } },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.statusFormat(
                                      _vm.productDetail.repayment,
                                      "repaymentType"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "服务费收取方式" } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.statusFormat(
                                  Number(_vm.productDetail.serviceChargeMethod),
                                  "ownFunds"
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.productDetail.maxAmount
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "借贷金额上限(元)" } },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.moneyFormat(
                                      _vm.productDetail.maxAmount,
                                      2
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productDetail.maxPeriodMin &&
                  _vm.productDetail.maxPeriodMax &&
                  _vm.productDetail.repayment !== "regular"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "额度期限(天)",
                                prop: "maxPeriod"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "60%" },
                                attrs: {
                                  type: "number",
                                  disabled: _vm.disabledMaxPeriod,
                                  placeholder: _vm.placeholderMaxPeriod
                                },
                                on: { input: _vm.utils.formatDecimal },
                                model: {
                                  value: _vm.appForm.maxPeriod,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "maxPeriod", $$v)
                                  },
                                  expression: "appForm.maxPeriod"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productDetail.minCycleMin && _vm.productDetail.minCycleMax
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "免收费期限(天)",
                                prop: "minCycle"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "60%" },
                                attrs: {
                                  type: "number",
                                  disabled: _vm.disabledMinCycle,
                                  placeholder: _vm.placeholderMinCycle
                                },
                                on: { input: _vm.utils.formatDecimal },
                                model: {
                                  value: _vm.appForm.minCycle,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "minCycle", $$v)
                                  },
                                  expression: "appForm.minCycle"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productDetail.interestRateMin &&
                  _vm.productDetail.interestRateMax
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "日利率(%)",
                                prop: "interestRate"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "60%" },
                                attrs: {
                                  type: "number",
                                  disabled: _vm.disabledInterestRate,
                                  placeholder: _vm.placeholderInterestRate
                                },
                                model: {
                                  value: _vm.appForm.interestRate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "interestRate", $$v)
                                  },
                                  expression: "appForm.interestRate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productDetail.penalTYtRateMin &&
                  _vm.productDetail.penalTYtRateMax
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "罚息(%)", prop: "penalTYtRate" }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "60%" },
                                attrs: {
                                  type: "number",
                                  disabled: _vm.disabledPenalTYtRate,
                                  placeholder: _vm.placeholderPenalTYtRate
                                },
                                model: {
                                  value: _vm.appForm.penalTYtRate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "penalTYtRate", $$v)
                                  },
                                  expression: "appForm.penalTYtRate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productDetail.roll
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否支持宽限期" } },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.statusFormat(
                                      _vm.productDetail.roll,
                                      "rollType"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productDetail.maxExtensionMin &&
                  _vm.productDetail.maxExtensionMax &&
                  _vm.productDetail.roll === "yes"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "宽限期最大时长",
                                prop: "maxExtension"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "60%" },
                                attrs: {
                                  type: "number",
                                  disabled: _vm.disabledMaxExtension,
                                  placeholder: _vm.placeholderMaxExtension
                                },
                                model: {
                                  value: _vm.appForm.maxExtension,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "maxExtension", $$v)
                                  },
                                  expression: "appForm.maxExtension"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productDetail.overdueMin && _vm.productDetail.overdueMax
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "逾期期限", prop: "overdue" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "60%" },
                                attrs: {
                                  type: "number",
                                  disabled: _vm.disabledOverdue,
                                  placeholder: _vm.placeholderOverdue
                                },
                                model: {
                                  value: _vm.appForm.overdue,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "overdue", $$v)
                                  },
                                  expression: "appForm.overdue"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productDetail.extensionRateMin &&
                  _vm.productDetail.extensionRateMax &&
                  _vm.productDetail.roll === "yes"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "宽限期利率(%)",
                                prop: "extensionRate"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "60%" },
                                attrs: {
                                  type: "number",
                                  disabled: _vm.disabledExtensionRate,
                                  placeholder: _vm.placeholderExtensionRate
                                },
                                model: {
                                  value: _vm.appForm.extensionRate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "extensionRate", $$v)
                                  },
                                  expression: "appForm.extensionRate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productDetail.financingMin &&
                  _vm.productDetail.financingMax
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "融资比例(%)", prop: "financing" }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "60%" },
                                attrs: {
                                  type: "number",
                                  disabled: _vm.disabledFinancing,
                                  placeholder: _vm.placeholderFinancing
                                },
                                model: {
                                  value: _vm.appForm.financing,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "financing", $$v)
                                  },
                                  expression: "appForm.financing"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productDetail.serviceChargeMin &&
                  _vm.productDetail.serviceChargeMax
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "服务费比例(%)",
                                prop: "serviceCharge"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "60%" },
                                attrs: {
                                  type: "number",
                                  disabled: _vm.disabledServiceCharge,
                                  placeholder: _vm.placeholderServiceCharge
                                },
                                model: {
                                  value: _vm.appForm.serviceCharge,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "serviceCharge", $$v)
                                  },
                                  expression: "appForm.serviceCharge"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productDetail.repayment === "regular"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "期数" } }, [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.utils.isEffectiveCommon(
                                    _vm.productDetail.periods
                                  )
                                )
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productDetail.repayment === "regular"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "还款日" } }, [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.utils.isEffectiveCommon(
                                    _vm.productDetail.repaymentDay
                                  )
                                )
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.stage === 2 && _vm.isSupplement && !_vm.viewDetail()
        ? _c(
            "ics-page-inner",
            { attrs: { title: "产品信息", "show-header": _vm.showHeader } },
            [
              _c(
                "el-form",
                {
                  ref: "appForm",
                  attrs: {
                    model: _vm.appForm,
                    rules: _vm.rules,
                    "label-width": "180px",
                    "label-suffix": _vm.constants.labelSuffix
                  }
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "金融产品编码" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.productDetail.productCode
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "金融产品名称" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.productDetail.productName
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "隶属资方" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.productDetail.capName
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "产品类型" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.statusFormat(
                                Number(_vm.productDetail.productFinancing),
                                "productType"
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "产品模型" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.productDetail.productTypeName
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否支持线上放/还款" } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.statusFormat(
                                  Number(_vm.productDetail.repaymentMethod),
                                  "isOnLine"
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.productDetail.repayment
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "repayment", label: "还款方式" } },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.statusFormat(
                                      _vm.productDetail.repayment,
                                      "repaymentType"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "服务费收取方式" } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.statusFormat(
                                  Number(_vm.productDetail.serviceChargeMethod),
                                  "ownFunds"
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.productDetail.maxAmount
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "借贷金额上限(元)" } },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.moneyFormat(
                                      _vm.productDetail.maxAmount,
                                      2
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productDetail.maxPeriod &&
                  _vm.productDetail.repayment !== "regular"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "额度期限(天)",
                                rules: {
                                  required: true,
                                  message: "请输入额度期限",
                                  trigger: "blur"
                                }
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "60%" },
                                attrs: {
                                  type: "number",
                                  placeholder: "请输入额度期限"
                                },
                                on: { input: _vm.utils.formatDecimal },
                                model: {
                                  value: _vm.appForm.maxPeriod,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "maxPeriod", $$v)
                                  },
                                  expression: "appForm.maxPeriod"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productDetail.minCycle
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "免收费期限(天)",
                                prop: "minCycle",
                                rules: {
                                  required: true,
                                  message: "请输入免收费期限",
                                  trigger: "blur"
                                }
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "60%" },
                                attrs: {
                                  type: "number",
                                  placeholder: "请输入免收费期限"
                                },
                                on: { input: _vm.utils.formatDecimal },
                                model: {
                                  value: _vm.appForm.minCycle,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "minCycle", $$v)
                                  },
                                  expression: "appForm.minCycle"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productDetail.interestRate
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "日利率(%)",
                                prop: "interestRate",
                                rules: {
                                  required: true,
                                  message: "请输入日利率",
                                  trigger: "blur"
                                }
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "60%" },
                                attrs: {
                                  type: "number",
                                  placeholder: "请输入日利率"
                                },
                                model: {
                                  value: _vm.appForm.interestRate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "interestRate", $$v)
                                  },
                                  expression: "appForm.interestRate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productDetail.penalTYtRate
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "罚息(%)",
                                prop: "penalTYtRate",
                                rules: {
                                  required: true,
                                  message: "请输入罚息",
                                  trigger: "blur"
                                }
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "60%" },
                                attrs: {
                                  type: "number",
                                  placeholder: "请输入罚息"
                                },
                                model: {
                                  value: _vm.appForm.penalTYtRate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "penalTYtRate", $$v)
                                  },
                                  expression: "appForm.penalTYtRate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productDetail.roll
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否支持宽限期" } },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.statusFormat(
                                      _vm.productDetail.roll,
                                      "rollType"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productDetail.maxExtension &&
                  _vm.productDetail.roll === "yes"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "宽限期最大时长",
                                prop: "maxExtension",
                                rules: {
                                  required: true,
                                  message: "请输入宽限期最大时长",
                                  trigger: "blur"
                                }
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "60%" },
                                attrs: {
                                  type: "number",
                                  placeholder: "请输入宽限期最大时长"
                                },
                                model: {
                                  value: _vm.appForm.maxExtension,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "maxExtension", $$v)
                                  },
                                  expression: "appForm.maxExtension"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productDetail.overdue
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "逾期期限",
                                prop: "overdue",
                                rules: {
                                  required: true,
                                  message: "请输入逾期期限",
                                  trigger: "blur"
                                }
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "60%" },
                                attrs: {
                                  type: "number",
                                  placeholder: "请输入逾期期限"
                                },
                                model: {
                                  value: _vm.appForm.overdue,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "overdue", $$v)
                                  },
                                  expression: "appForm.overdue"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productDetail.extensionRate &&
                  _vm.productDetail.roll === "yes"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "宽限期利率(%)",
                                prop: "extensionRate",
                                rules: {
                                  required: true,
                                  message: "请输入宽限期利率",
                                  trigger: "blur"
                                }
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "60%" },
                                attrs: {
                                  type: "number",
                                  placeholder: "请输入宽限期利率"
                                },
                                model: {
                                  value: _vm.appForm.extensionRate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "extensionRate", $$v)
                                  },
                                  expression: "appForm.extensionRate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productDetail.financing
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "融资比例(%)",
                                prop: "financing",
                                rules: {
                                  required: true,
                                  message: "请输入融资比例",
                                  trigger: "blur"
                                }
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "60%" },
                                attrs: {
                                  type: "number",
                                  placeholder: "请输入融资比例"
                                },
                                model: {
                                  value: _vm.appForm.financing,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "financing", $$v)
                                  },
                                  expression: "appForm.financing"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productDetail.serviceCharge
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "服务费比例(%)",
                                prop: "serviceCharge",
                                rules: {
                                  required: true,
                                  message: "请输入服务费比例",
                                  trigger: "blur"
                                }
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "60%" },
                                attrs: {
                                  type: "number",
                                  placeholder: "请输入服务费比例"
                                },
                                model: {
                                  value: _vm.appForm.serviceCharge,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "serviceCharge", $$v)
                                  },
                                  expression: "appForm.serviceCharge"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productDetail.repayment === "regular"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "期数" } }, [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.utils.isEffectiveCommon(
                                    _vm.productDetail.periods
                                  )
                                )
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productDetail.repayment === "regular"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "还款日" } }, [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.utils.isEffectiveCommon(
                                    _vm.productDetail.repaymentDay
                                  )
                                )
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }