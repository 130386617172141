<template>
  <div>
    <ics-page-inner v-if="stage === 0 && !isSupplement" title="产品信息" :show-header="showHeader">
      <el-col :span="12">
        <el-form-item label="金融产品编码">
          <p>{{ utils.isEffectiveCommon(productDetail.productCode) }}</p>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="金融产品名称">
          <p>{{ utils.isEffectiveCommon(productDetail.productName) }}</p>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="隶属资方">
          <p>{{ utils.isEffectiveCommon(productDetail.capName) }}</p>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="产品类型">
          <p>{{ utils.statusFormat(Number(productDetail.productFinancing), 'productType') }}</p>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="产品模型">
          <p>{{ utils.isEffectiveCommon(productDetail.productTypeName) }}</p>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="是否支持线上放/还款">
          <p>{{ utils.statusFormat(Number(productDetail.repaymentMethod), 'isOnLine') }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.repayment" :span="12">
        <el-form-item prop="repayment" label="还款方式">
          <p>{{ utils.statusFormat(productDetail.repayment, 'repaymentType') }}</p>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="服务费收取方式">
          <p>{{ utils.statusFormat(Number(productDetail.serviceChargeMethod), 'ownFunds') }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.maxAmount" :span="12">
        <el-form-item label="借贷金额上限(元)">
          <p>{{ utils.moneyFormat(productDetail.maxAmount, 2) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.minCycleMin && productDetail.minCycleMax" :span="12">
        <el-form-item label="免收费期限(天)">
          <p>{{ (utils.isEffectiveCommon(productDetail.minCycleMin) + ' ~ ' + utils.isEffectiveCommon(productDetail.minCycleMax)) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.maxPeriodMin && productDetail.maxPeriodMax && productDetail.repayment !== 'regular'" :span="12">
        <el-form-item label="额度期限(天)">
          <p>{{ (utils.isEffectiveCommon(productDetail.maxPeriodMin) + ' ~ ' + utils.isEffectiveCommon(productDetail.maxPeriodMax)) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.interestRateMin && productDetail.interestRateMax" :span="12">
        <el-form-item label="日利率(%)">
          <p>{{ (utils.isEffectiveCommon(productDetail.interestRateMin) + ' ~ ' + utils.isEffectiveCommon(productDetail.interestRateMax)) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.penalTYtRateMin && productDetail.penalTYtRateMax" :span="12">
        <el-form-item label="罚息(%)">
          <p>{{ (utils.isEffectiveCommon(productDetail.penalTYtRateMin) + ' ~ ' + utils.isEffectiveCommon(productDetail.penalTYtRateMax)) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.overdueMin && productDetail.overdueMax" :span="12">
        <el-form-item label="逾期期限">
          <p>{{ (utils.isEffectiveCommon(productDetail.overdueMin) + ' ~ ' + utils.isEffectiveCommon(productDetail.overdueMax)) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.roll" :span="12">
        <el-form-item label="是否支持宽限期">
          <p>{{ utils.statusFormat(productDetail.roll, 'rollType') }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.maxExtensionMin && productDetail.maxExtensionMax && productDetail.roll === 'yes'" :span="12">
        <el-form-item label="宽限期最大时长">
          <p>{{ (utils.isEffectiveCommon(productDetail.maxExtensionMin) + ' ~ ' + utils.isEffectiveCommon(productDetail.maxExtensionMax)) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.repayment === 'regular'" :span="12">
        <el-form-item label="期数">
          <p>{{ utils.isEffectiveCommon(productDetail.periods) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.extensionRateMin && productDetail.extensionRateMax && productDetail.roll === 'yes'" :span="12">
        <el-form-item label="宽限期利率(%)">
          <p>{{ (utils.isEffectiveCommon(productDetail.extensionRateMin) + ' ~ ' + utils.isEffectiveCommon(productDetail.extensionRateMax)) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.financingMin && productDetail.financingMax" :span="12">
        <el-form-item label="融资比例(%)">
          <p>{{ (utils.isEffectiveCommon(productDetail.financingMin) + ' ~ ' + utils.isEffectiveCommon(productDetail.financingMax)) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.serviceChargeMin && productDetail.serviceChargeMax" :span="12">
        <el-form-item label="服务费比例(%)">
          <p>{{ (utils.isEffectiveCommon(productDetail.serviceChargeMin) + ' ~ ' + utils.isEffectiveCommon(productDetail.serviceChargeMax)) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.repayment === 'regular'" :span="12">
        <el-form-item label="还款日：">
          <p>{{ utils.isEffectiveCommon(productDetail.repaymentDay) }}</p>
        </el-form-item>
      </el-col>
    </ics-page-inner>
    <ics-page-inner v-if="stage === 1 && !isSupplement" title="产品信息" :show-header="showHeader">
      <el-col :span="12">
        <el-form-item label="金融产品编码">
          <p>{{ utils.isEffectiveCommon(productDetail.productCode) }}</p>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="金融产品名称">
          <p>{{ utils.isEffectiveCommon(productDetail.productName) }}</p>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="隶属资方">
          <p>{{ utils.isEffectiveCommon(productDetail.capName) }}</p>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="产品类型">
          <p>{{ utils.statusFormat(Number(productDetail.productFinancing), 'productType') }}</p>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="产品模型">
          <p>{{ utils.isEffectiveCommon(productDetail.productTypeName) }}</p>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="是否支持线上放/还款">
          <p>{{ utils.statusFormat(Number(productDetail.repaymentMethod), 'isOnLine') }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.repayment" :span="12">
        <el-form-item prop="repayment" label="还款方式">
          <p>{{ utils.statusFormat(productDetail.repayment, 'repaymentType') }}</p>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="服务费收取方式">
          <p>{{ utils.statusFormat(Number(productDetail.serviceChargeMethod), 'ownFunds') }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.maxAmount" :span="12">
        <el-form-item label="借贷金额上限(元)">
          <p>{{ utils.moneyFormat(productDetail.maxAmount, 2) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.minCycle" :span="12">
        <el-form-item label="免收费期限(天)">
          <p>{{ utils.isEffectiveCommon(productDetail.minCycle) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.maxPeriod && productDetail.repayment !== 'regular'" :span="12">
        <el-form-item label="额度期限(天)">
          <p>{{ utils.isEffectiveCommon(productDetail.maxPeriod) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.interestRate" :span="12">
        <el-form-item label="日利率(%)">
          <p>{{ utils.isEffectiveCommon(productDetail.interestRate) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.penalTYtRate" :span="12">
        <el-form-item label="罚息(%)">
          <p>{{ utils.isEffectiveCommon(productDetail.penalTYtRate) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.roll" :span="12">
        <el-form-item label="是否支持宽限期">
          <p>{{ utils.statusFormat(productDetail.roll, 'rollType') }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.maxExtension && productDetail.roll === 'yes'" :span="12">
        <el-form-item label="宽限期最大时长">
          <p>{{ utils.isEffectiveCommon(productDetail.maxExtension) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.overdue" :span="12">
        <el-form-item label="逾期期限">
          <p>{{ utils.isEffectiveCommon(productDetail.overdue) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.extensionRate && productDetail.roll === 'yes'" :span="12">
        <el-form-item label="宽限期利率(%)">
          <p>{{ utils.isEffectiveCommon(productDetail.extensionRate) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.financing" :span="12">
        <el-form-item label="融资比例(%)">
          <p>{{ utils.isEffectiveCommon(productDetail.financing) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.serviceCharge" :span="12">
        <el-form-item label="服务费比例(%)">
          <p>{{ utils.isEffectiveCommon(productDetail.serviceCharge) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.repayment === 'regular'" :span="12">
        <el-form-item label="期数">
          <p>{{ utils.isEffectiveCommon(productDetail.periods) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.repayment === 'regular'" :span="12">
        <el-form-item label="还款日：">
          <p>{{ utils.isEffectiveCommon(productDetail.repaymentDay) }}</p>
        </el-form-item>
      </el-col>
    </ics-page-inner>
    <ics-page-inner v-if="stage === 2 && !isSupplement && !viewDetail()" title="产品信息" :show-header="showHeader">
      <el-form ref="appForm" :model="appForm" :rules="rules" label-width="180px" :label-suffix="constants.labelSuffix">
        <el-col :span="12">
          <el-form-item label="金融产品编码">
            <p>{{ utils.isEffectiveCommon(productDetail.productCode) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="金融产品名称">
            <p>{{ utils.isEffectiveCommon(productDetail.productName) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="隶属资方">
            <p>{{ utils.isEffectiveCommon(productDetail.capName) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="产品类型">
            <p>{{ utils.statusFormat(Number(productDetail.productFinancing), 'productType') }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="产品模型">
            <p>{{ utils.isEffectiveCommon(productDetail.productTypeName) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否支持线上放/还款">
            <p>{{ utils.statusFormat(Number(productDetail.repaymentMethod), 'isOnLine') }}</p>
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.repayment" :span="12">
          <el-form-item prop="repayment" label="还款方式">
            <p>{{ utils.statusFormat(productDetail.repayment, 'repaymentType') }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="服务费收取方式">
            <p>{{ utils.statusFormat(Number(productDetail.serviceChargeMethod), 'ownFunds') }}</p>
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.maxAmount" :span="12">
          <el-form-item label="借贷金额上限(元)">
            <p>{{ utils.moneyFormat(productDetail.maxAmount, 2) }}</p>
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.maxPeriodMin && productDetail.maxPeriodMax && productDetail.repayment !== 'regular'" :span="12">
          <el-form-item label="额度期限(天)" prop="maxPeriod">
            <el-input v-model="appForm.maxPeriod" type="number" :disabled="disabledMaxPeriod" style="width: 60%" :placeholder="placeholderMaxPeriod" @input="utils.formatDecimal" />
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.minCycleMin && productDetail.minCycleMax" :span="12">
          <el-form-item label="免收费期限(天)" prop="minCycle">
            <el-input v-model="appForm.minCycle" type="number" :disabled="disabledMinCycle" style="width: 60%" :placeholder="placeholderMinCycle" @input="utils.formatDecimal" />
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.interestRateMin && productDetail.interestRateMax" :span="12">
          <el-form-item label="日利率(%)" prop="interestRate">
            <el-input v-model="appForm.interestRate" type="number" :disabled="disabledInterestRate" style="width: 60%" :placeholder="placeholderInterestRate" />
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.penalTYtRateMin && productDetail.penalTYtRateMax" :span="12">
          <el-form-item label="罚息(%)" prop="penalTYtRate">
            <el-input v-model="appForm.penalTYtRate" type="number" :disabled="disabledPenalTYtRate" style="width: 60%" :placeholder="placeholderPenalTYtRate" />
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.roll" :span="12">
          <el-form-item label="是否支持宽限期">
            <p>{{ utils.statusFormat(productDetail.roll,'rollType') }}</p>
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.maxExtensionMin && productDetail.maxExtensionMax && productDetail.roll === 'yes'" :span="12">
          <el-form-item label="宽限期最大时长" prop="maxExtension">
            <el-input v-model="appForm.maxExtension" type="number" :disabled="disabledMaxExtension" style="width: 60%" :placeholder="placeholderMaxExtension" />
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.overdueMin && productDetail.overdueMax" :span="12">
          <el-form-item label="逾期期限" prop="overdue">
            <el-input v-model="appForm.overdue" type="number" :disabled="disabledOverdue" style="width: 60%" :placeholder="placeholderOverdue" />
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.extensionRateMin && productDetail.extensionRateMax && productDetail.roll === 'yes'" :span="12">
          <el-form-item label="宽限期利率(%)" prop="extensionRate">
            <el-input v-model="appForm.extensionRate" type="number" :disabled="disabledExtensionRate" style="width: 60%" :placeholder="placeholderExtensionRate" />
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.financingMin && productDetail.financingMax" :span="12">
          <el-form-item label="融资比例(%)" prop="financing">
            <el-input v-model="appForm.financing" type="number" :disabled="disabledFinancing" style="width: 60%" :placeholder="placeholderFinancing" />
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.serviceChargeMin && productDetail.serviceChargeMax" :span="12">
          <el-form-item label="服务费比例(%)" prop="serviceCharge">
            <el-input v-model="appForm.serviceCharge" type="number" :disabled="disabledServiceCharge" style="width: 60%" :placeholder="placeholderServiceCharge" />
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.repayment === 'regular'" :span="12">
          <el-form-item label="期数">
            <p>{{ utils.isEffectiveCommon(productDetail.periods) }}</p>
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.repayment === 'regular'" :span="12">
          <el-form-item label="还款日">
            <p>{{ utils.isEffectiveCommon(productDetail.repaymentDay) }}</p>
          </el-form-item>
        </el-col>
      </el-form>
    </ics-page-inner>
    <ics-page-inner v-if="stage === 2 && isSupplement && !viewDetail()" title="产品信息" :show-header="showHeader">
      <el-form ref="appForm" :model="appForm" :rules="rules" label-width="180px" :label-suffix="constants.labelSuffix">
        <el-col :span="12">
          <el-form-item label="金融产品编码">
            <p>{{ utils.isEffectiveCommon(productDetail.productCode) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="金融产品名称">
            <p>{{ utils.isEffectiveCommon(productDetail.productName) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="隶属资方">
            <p>{{ utils.isEffectiveCommon(productDetail.capName) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="产品类型">
            <p>{{ utils.statusFormat(Number(productDetail.productFinancing), 'productType') }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="产品模型">
            <p>{{ utils.isEffectiveCommon(productDetail.productTypeName) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否支持线上放/还款">
            <p>{{ utils.statusFormat(Number(productDetail.repaymentMethod), 'isOnLine') }}</p>
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.repayment" :span="12">
          <el-form-item prop="repayment" label="还款方式">
            <p>{{ utils.statusFormat(productDetail.repayment, 'repaymentType') }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="服务费收取方式">
            <p>{{ utils.statusFormat(Number(productDetail.serviceChargeMethod), 'ownFunds') }}</p>
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.maxAmount" :span="12">
          <el-form-item label="借贷金额上限(元)">
            <p>{{ utils.moneyFormat(productDetail.maxAmount, 2) }}</p>
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.maxPeriod && productDetail.repayment !== 'regular'" :span="12">
          <el-form-item label="额度期限(天)" :rules="{ required: true, message: '请输入额度期限', trigger: 'blur' }">
            <el-input v-model="appForm.maxPeriod" type="number" style="width: 60%" placeholder="请输入额度期限" @input="utils.formatDecimal" />
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.minCycle" :span="12">
          <el-form-item label="免收费期限(天)" prop="minCycle" :rules="{ required: true, message: '请输入免收费期限', trigger: 'blur' }">
            <el-input v-model="appForm.minCycle" type="number" style="width: 60%" placeholder="请输入免收费期限" @input="utils.formatDecimal" />
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.interestRate" :span="12">
          <el-form-item label="日利率(%)" prop="interestRate" :rules="{ required: true, message: '请输入日利率', trigger: 'blur' }">
            <el-input v-model="appForm.interestRate" type="number" style="width: 60%" placeholder="请输入日利率" />
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.penalTYtRate" :span="12">
          <el-form-item label="罚息(%)" prop="penalTYtRate" :rules="{ required: true, message: '请输入罚息', trigger: 'blur' }">
            <el-input v-model="appForm.penalTYtRate" type="number" style="width: 60%" placeholder="请输入罚息" />
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.roll" :span="12">
          <el-form-item label="是否支持宽限期">
            <p>{{ utils.statusFormat(productDetail.roll,'rollType') }}</p>
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.maxExtension && productDetail.roll === 'yes'" :span="12">
          <el-form-item label="宽限期最大时长" prop="maxExtension" :rules="{ required: true, message: '请输入宽限期最大时长', trigger: 'blur' }">
            <el-input v-model="appForm.maxExtension" type="number" style="width: 60%" placeholder="请输入宽限期最大时长" />
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.overdue" :span="12">
          <el-form-item label="逾期期限" prop="overdue" :rules="{ required: true, message: '请输入逾期期限', trigger: 'blur' }">
            <el-input v-model="appForm.overdue" type="number" style="width: 60%" placeholder="请输入逾期期限" />
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.extensionRate && productDetail.roll === 'yes'" :span="12">
          <el-form-item label="宽限期利率(%)" prop="extensionRate" :rules="{ required: true, message: '请输入宽限期利率', trigger: 'blur' }">
            <el-input v-model="appForm.extensionRate" type="number" style="width: 60%" placeholder="请输入宽限期利率" />
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.financing" :span="12">
          <el-form-item label="融资比例(%)" prop="financing" :rules="{ required: true, message: '请输入融资比例', trigger: 'blur' }">
            <el-input v-model="appForm.financing" type="number" style="width: 60%" placeholder="请输入融资比例" />
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.serviceCharge" :span="12">
          <el-form-item label="服务费比例(%)" prop="serviceCharge" :rules="{ required: true, message: '请输入服务费比例', trigger: 'blur' }">
            <el-input v-model="appForm.serviceCharge" type="number" style="width: 60%" placeholder="请输入服务费比例" />
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.repayment === 'regular'" :span="12">
          <el-form-item label="期数">
            <p>{{ utils.isEffectiveCommon(productDetail.periods) }}</p>
          </el-form-item>
        </el-col>
        <el-col v-if="productDetail.repayment === 'regular'" :span="12">
          <el-form-item label="还款日">
            <p>{{ utils.isEffectiveCommon(productDetail.repaymentDay) }}</p>
          </el-form-item>
        </el-col>
      </el-form>
    </ics-page-inner>
  </div>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import utils from '../../../assets/js/utils'
export default {
  name: 'IcsProductInfoInner',
  components: {},
  mixins: [routeEnterMixin],
  props: {
    showHeader: {
      type: Boolean,
      default: false
    },
    productInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    stage: {
      type: Number,
      default: 0
    },
    viewEditInfo: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const validateMaxPeriod = (rule, value, callback) => {
      if (value) {
        if (Number(value) < this.productDetail.maxPeriodMin || Number(value) > this.productDetail.maxPeriodMax) {
          callback(new Error(`请输入${utils.valFormat(this.productDetail.maxPeriodMin, this.productDetail.maxPeriodMax, '天')}内的额度期限`))
        } else {
          callback()
        }
      } else {
        callback(new Error('请输入额度期限'))
      }
    }
    const validateMinCycle = (rule, value, callback) => {
      if (value) {
        if (Number(value) < this.productDetail.minCycleMin || Number(value) > this.productDetail.minCycleMax) {
          callback(new Error(`请输入${utils.valFormat(this.productDetail.minCycleMin, this.productDetail.minCycleMax, '天')}内的免收费期限`))
        } else {
          callback()
        }
      } else {
        callback(new Error('请输入免收费期限'))
      }
    }
    const validateInterestRate = (rule, value, callback) => {
      if (Number(value) < this.productDetail.interestRateMin || Number(value) > this.productDetail.interestRateMax) {
        callback(new Error(`请输入${utils.valFormat(this.productDetail.interestRateMin, this.productDetail.interestRateMax, '%')}内的日利率`))
      } else {
        callback()
      }
    }
    const validatePenalTYtRate = (rule, value, callback) => {
      if (Number(value) < this.productDetail.penalTYtRateMin || Number(value) > this.productDetail.penalTYtRateMax) {
        callback(new Error(`请输入${utils.valFormat(this.productDetail.penalTYtRateMin, this.productDetail.penalTYtRateMax, '%')}内的罚息`))
      } else {
        callback()
      }
    }
    const validateOverdue = (rule, value, callback) => {
      if (Number(value) < this.productDetail.overdueMin || Number(value) > this.productDetail.overdueMax) {
        callback(new Error(`请输入${utils.valFormat(this.productDetail.overdueMin, this.productDetail.overdueMax, '天')}内的逾期期限`))
      } else {
        callback()
      }
    }
    const validateMaxExtension = (rule, value, callback) => {
      if (Number(value) < this.productDetail.maxExtensionMin || Number(value) > this.productDetail.maxExtensionMax) {
        callback(new Error(`请输入${utils.valFormat(this.productDetail.maxExtensionMin, this.productDetail.maxExtensionMax, '天')}内的展期时长`))
      } else {
        callback()
      }
    }
    const validateExtensionRate = (rule, value, callback) => {
      if (Number(value) < this.productDetail.extensionRateMin || Number(value) > this.productDetail.extensionRateMax) {
        callback(new Error(`请输入${utils.valFormat(this.productDetail.extensionRateMin, this.productDetail.extensionRateMax, '%')}内的宽限期利率`))
      } else {
        callback()
      }
    }
    const validateFinancing = (rule, value, callback) => {
      if (Number(value) < this.productDetail.financingMin || Number(value) > this.productDetail.financingMax) {
        callback(new Error(`请输入${utils.valFormat(this.productDetail.financingMin, this.productDetail.financingMax, '%')}内的融资比例`))
      } else {
        callback()
      }
    }
    const validateServiceCharge = (rule, value, callback) => {
      if (Number(value) < this.productDetail.serviceChargeMin || Number(value) > this.productDetail.serviceChargeMax) {
        callback(new Error(`请输入${utils.valFormat(this.productDetail.serviceChargeMin, this.productDetail.serviceChargeMax, '%')}内的服务费比例`))
      } else {
        callback()
      }
    }
    return {
      productDetail: {
        id: '',
        productCode: '',
        productName: '',
        capId: '',
        capCode: '',
        capName: '',
        productFinancing: '',
        productTypeName: '',
        state: '',
        roll: '',
        repayment: '',
        repaymentMethod: '',
        serviceChargeMethod: '',
        maxAmount: '',
        maxPeriodMin: '',
        maxPeriodMax: '',
        penalTYtRateMin: '',
        penalTYtRateMax: '',
        minCycleMin: '',
        minCycleMax: '',
        interestRateMin: '',
        interestRateMax: '',
        overdueMin: '',
        overdueMax: '',
        maxExtensionMin: '',
        maxExtensionMax: '',
        extensionRateMin: '',
        extensionRateMax: '',
        financingMin: '',
        financingMax: '',
        serviceChargeMin: '',
        serviceChargeMax: '',
        repaymentDay: '',
        periods: ''
      },
      isSupplement: false,
      appForm: {
        maxPeriod: '',
        serviceCharge: '',
        minCycle: '',
        interestRate: '',
        penalTYtRate: '',
        overdue: '',
        maxExtension: '',
        extensionRate: '',
        financing: ''
      },
      disabledMaxPeriod: false,
      disabledMinCycle: false,
      disabledInterestRate: false,
      disabledPenalTYtRate: false,
      disabledMaxExtension: false,
      disabledOverdue: false,
      disabledExtensionRate: false,
      disabledFinancing: false,
      disabledServiceCharge: false,
      placeholderMaxPeriod: '',
      placeholderMinCycle: '',
      placeholderInterestRate: '',
      placeholderPenalTYtRate: '',
      placeholderOverdue: '',
      placeholderMaxExtension: '',
      placeholderExtensionRate: '',
      placeholderFinancing: '',
      placeholderServiceCharge: '',
      rules: {
        maxPeriod: [
          { required: true, message: '请输入额度期限', trigger: 'blur' },
          { validator: validateMaxPeriod, trigger: 'blur' }
        ],
        minCycle: [
          { required: true, message: '请输入免收费期限', trigger: 'blur' },
          { validator: validateMinCycle, trigger: 'blur' }
        ],
        interestRate: [
          { required: true, message: '请输入日利率', trigger: 'blur' },
          { validator: validateInterestRate, trigger: 'blur' }
        ],
        penalTYtRate: [
          { required: true, message: '请输入罚息', trigger: 'blur' },
          { validator: validatePenalTYtRate, trigger: 'blur' }
        ],
        overdue: [
          { required: true, message: '请输入逾期期限', trigger: 'blur' },
          { validator: validateOverdue, trigger: 'blur' }
        ],
        maxExtension: [
          { required: true, message: '请输入宽限期最大时长', trigger: 'blur' },
          { validator: validateMaxExtension, trigger: 'blur' }
        ],
        extensionRate: [
          { required: true, message: '请输入宽限期利率', trigger: 'blur' },
          { validator: validateExtensionRate, trigger: 'blur' }
        ],
        financing: [
          { required: true, message: '请输入融资比例', trigger: 'blur' },
          { validator: validateFinancing, trigger: 'blur' }
        ],
        serviceCharge: [
          { required: true, message: '请输入服务费比例', trigger: 'blur' },
          { validator: validateServiceCharge, trigger: 'blur' }
        ],
      }
    }
  },
  watch: {
    productInfo: {
      handler (val) {
        if (val) {
          this.getDataProcessing(val)
        }
      },
      immediate: true
    }
  },
  methods: {
    viewDetail () {
      if (['detail', 'audit'].includes(this.$route.params.editMode)) {
        return true
      } else {
        return false
      }
    },
    getDataProcessing (info) {
      const data = info || {}
      if (data.isSupplement && this.$route.params.editMode === 'supplement') {
        this.productDetail = data || {}
        this.isSupplement = true
        data.penalTYtRate = data.penaltytRate
        const { maxPeriod, serviceCharge, minCycle, interestRate, penalTYtRate, overdue, maxExtension, extensionRate, financing }  = data
        this.appForm = this._.assign(this.appForm, { maxPeriod, serviceCharge, minCycle, interestRate, penalTYtRate, overdue, maxExtension, extensionRate, financing })
      } else {
        if (this.stage === 1) {
          data.penalTYtRate = data.penaltytRate
          this.productDetail = data || {}
        } else {
          let overdue = ''
          let maxExtension = ''
          let extensionRate = ''
          let penalTYtRate = ''
          let interestRate = ''
          let minCycle = ''
          let maxPeriod = ''
          let financing = ''
          let serviceCharge = ''
          if (data.maxPeriod) {
            maxPeriod = JSON.parse(data.maxPeriod)
            data.maxPeriodMin = maxPeriod.min
            data.maxPeriodMax = maxPeriod.max
          }
          if (data.minCycle) {
            minCycle = JSON.parse(data.minCycle)
            data.minCycleMin = minCycle.min
            data.minCycleMax = minCycle.max
          }
          if (data.interestRate) {
            interestRate = JSON.parse(data.interestRate)
            data.interestRateMin = interestRate.min
            data.interestRateMax = interestRate.max
          }
          if (data.penaltytRate) {
            penalTYtRate = JSON.parse(data.penaltytRate)
            data.penalTYtRateMin = penalTYtRate.min
            data.penalTYtRateMax = penalTYtRate.max
          }
          if (data.overdue) {
            overdue = JSON.parse(data.overdue)
            data.overdueMin = overdue.min
            data.overdueMax = overdue.max
          }
          if (data.maxExtension) {
            maxExtension = JSON.parse(data.maxExtension)
            data.maxExtensionMin = maxExtension.min
            data.maxExtensionMax = maxExtension.max
          }
          if (data.extensionRate) {
            extensionRate = JSON.parse(data.extensionRate)
            data.extensionRateMin = extensionRate.min
            data.extensionRateMax = extensionRate.max
          }
          if (data.financing) {
            financing = JSON.parse(data.financing)
            data.financingMin = financing.min
            data.financingMax = financing.max
          }
          if (data.serviceCharge) {
            serviceCharge = JSON.parse(data.serviceCharge)
            data.serviceChargeMin = serviceCharge.min
            data.serviceChargeMax = serviceCharge.max
          }
          const {id, capCode, capId, state, productCode, productName, capName, productFinancing, productTypeName, repayment, repaymentMethod, serviceChargeMethod, maxAmount,
            maxPeriodMin, maxPeriodMax, penalTYtRateMin, penalTYtRateMax, minCycleMin, minCycleMax, interestRateMin,
            interestRateMax, overdueMin, overdueMax, maxExtensionMin, maxExtensionMax,
            extensionRateMin, extensionRateMax, financingMin, financingMax, serviceChargeMin,
            serviceChargeMax, roll, repaymentDay, periods} = data
          this.productDetail = this._.assign(this.productDetail, {id, capCode, capId, state, productCode, productName, capName, productFinancing, productTypeName, repayment, repaymentMethod, serviceChargeMethod, maxAmount,
            maxPeriodMin, maxPeriodMax, penalTYtRateMin, penalTYtRateMax, minCycleMin, minCycleMax, interestRateMin,
            interestRateMax, overdueMin, overdueMax, maxExtensionMin, maxExtensionMax,
            extensionRateMin, extensionRateMax, financingMin, financingMax, serviceChargeMin,
            serviceChargeMax, roll, repaymentDay, periods})
          if (this.stage === 2) {
            if (Number(this.productDetail.maxPeriodMin) === Number(this.productDetail.maxPeriodMax)) {
              this.disabledMaxPeriod = true
              this.appForm.maxPeriod = this.productDetail.maxPeriodMin
            }
            if (Number(this.productDetail.minCycleMin) === Number(this.productDetail.minCycleMax)) {
              this.disabledMinCycle = true
              this.appForm.minCycle = this.productDetail.minCycleMin
            }
            if (Number(this.productDetail.interestRateMin) === Number(this.productDetail.interestRateMax)) {
              this.disabledInterestRate = true
              this.appForm.interestRate = this.productDetail.interestRateMin
            }
            if (Number(this.productDetail.penalTYtRateMin) === Number(this.productDetail.penalTYtRateMax)) {
              this.disabledPenalTYtRate = true
              this.appForm.penalTYtRate = this.productDetail.penalTYtRateMin
            }
            if (Number(this.productDetail.overdueMin) === Number(this.productDetail.overdueMax)) {
              this.disabledMaxExtension = true
              this.appForm.overdue = this.productDetail.overdueMin
            }
            if (Number(this.productDetail.maxExtensionMin) === Number(this.productDetail.maxExtensionMax)) {
              this.disabledOverdue = true
              this.appForm.maxExtension = this.productDetail.maxExtensionMin
            }
            if (Number(this.productDetail.extensionRateMin) === Number(this.productDetail.extensionRateMax)) {
              this.disabledExtensionRate = true
              this.appForm.extensionRate = this.productDetail.extensionRateMin
            }
            if (Number(this.productDetail.financingMin) === Number(this.productDetail.financingMax)) {
              this.disabledFinancing = true
              this.appForm.financing = this.productDetail.financingMin
            }
            if (Number(this.productDetail.serviceChargeMin) === Number(this.productDetail.serviceChargeMax)) {
              this.disabledServiceCharge = true
              this.appForm.serviceCharge = this.productDetail.serviceChargeMin
            }
            if (Number(this.productDetail.serviceChargeMin) === Number(this.productDetail.serviceChargeMax)) {
              this.disabledServiceCharge = true
              this.appForm.serviceCharge = this.productDetail.serviceChargeMin
            }
            this.placeholderMaxPeriod = `请输入${utils.valFormat(this.productDetail.maxPeriodMin, this.productDetail.maxPeriodMax, '天')}`
            this.placeholderMinCycle = `请输入${utils.valFormat(this.productDetail.minCycleMin, this.productDetail.minCycleMax, '天')}`
            this.placeholderInterestRate = `请输入${utils.valFormat(this.productDetail.interestRateMin, this.productDetail.interestRateMax, '%')}`
            this.placeholderPenalTYtRate = `请输入${utils.valFormat(this.productDetail.penalTYtRateMin, this.productDetail.penalTYtRateMax, '%')}`
            this.placeholderOverdue = `请输入${utils.valFormat(this.productDetail.overdueMin, this.productDetail.overdueMax, '天')}`
            this.placeholderMaxExtension = `请输入${utils.valFormat(this.productDetail.maxExtensionMin, this.productDetail.maxExtensionMax, '天')}`
            this.placeholderExtensionRate = `请输入${utils.valFormat(this.productDetail.extensionRateMin, this.productDetail.extensionRateMax, '%')}`
            this.placeholderFinancing = `请输入${utils.valFormat(this.productDetail.financingMin, this.productDetail.financingMax, '%')}`
            this.placeholderServiceCharge = `请输入${utils.valFormat(this.productDetail.serviceChargeMin, this.productDetail.serviceChargeMax, '%')}`
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
